import { digit, required } from "./field-rules";

const codeLength = 4;
const codeLengthError = `Значения поля должно содержать ${codeLength} цифры`;

export default [
  {
    name: "code",
    attrs: {
      type: "text",
      label: "Код",
      required: true
    },
    rules: [
      required,
      digit,
      {
        type: "minLength",
        arg: codeLength,
        error: codeLengthError
      },
      {
        type: "maxLength",
        arg: codeLength,
        error: codeLengthError
      }
    ]
  }
];
