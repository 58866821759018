import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  Contract,
  ContractBalance,
  ContractsByOrganization
} from "@/models/contract";

@Module({ namespaced: true })
class ContractsModule extends VuexModule {
  list: Contract[] | null = null;
  balances: ContractBalance[] | null = null;

  /**
   * Возвращает массив, элементами которого являются объекты, каждый из
   * которых содержащит данные о единственном ЮЛ и договорах, заключенных с ним.
   * @returns {Array<ContractsByOrganization>}
   */
  get sortedContractsByOrganization(): ContractsByOrganization[] | null {
    const simpleObj: { [x: string]: any } = {};

    if (this.list) {
      const contracts = this.list.reduce((acc, contract) => {
        const companyId = contract["организация"];

        if (companyId in acc) {
          acc[companyId].contractList.push(contract);
        } else {
          const {
            название: name,
            инн: taxpayerId,
            кпп: taxpayerCode,
            адрес: address
          } = contract;

          acc[companyId] = {
            name,
            companyId,
            taxpayerId,
            taxpayerCode,
            address,
            contractList: [contract]
          };
        }

        return acc;
      }, simpleObj);

      return Object.values(contracts);
    }

    return null;
  }

  public get balanceList(): ContractBalance[] {
    return Array.isArray(this.balances) ? this.balances : [];
  }

  @Mutation
  setContracts(contracts: Contract[] | null) {
    this.list = contracts;
  }

  @Mutation
  setBalances(balances: ContractBalance[] | null) {
    this.balances = balances;
  }
}

export default ContractsModule;
