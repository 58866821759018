import { required } from "./field-rules";
import { password } from "./field-icons";

export default [
  {
    name: "username",
    attrs: {
      type: "text",
      label: "Логин",
      required: true
    },
    rules: [required]
  },
  {
    name: "password",
    attrs: {
      type: "password",
      label: "Пароль",
      required: true,
      appendIcons: password
    },
    rules: [required]
  }
];
