










































import { Component, Prop } from "vue-property-decorator";
import { formatDate } from "@/lib/date";
import { Contract } from "@/models/contract";
import XDropdown from "../SimpleDropdown.vue";
import ContractIcon from "../ContractIcon.vue";

@Component({
  components: { XDropdown, ContractIcon },
})
class ContractDropdown extends XDropdown {
  @Prop({ default: null }) readonly currentContract!: Contract | null;

  get titles() {
    const contract = this.currentContract;

    return contract
      ? {
          company: contract["название"],
          contractNumber: contract["номер"],
        }
      : { company: "", contractNumber: "" };
  }

  public getContractDetails(number: string, startDate: string) {
    return `№ ${number} от ${formatDate(startDate, "full")}`;
  }

  public isCurrentContract(contract: Contract): boolean {
    const key = "$номерЗаписи";

    return (
      !!this.currentContract && contract[key] === this.currentContract[key]
    );
  }
}

export default ContractDropdown;
