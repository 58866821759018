import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: {
          // Green
          base: "#1a965a",
          lighten5: "#d1eade"
        },
        secondary: {
          // Black
          base: "#030724",
          lighten1: "#525e74",
          lighten2: "#686a7c",
          lighten3: "#8a92a0"
        },
        error: {
          // Red
          base: "#ce574a",
          lighten4: "#f5dddb",
          lighten5: "#faeeed"
        },
        info: {
          // Blue
          base: "#3474c5",
          lighten5: "#d6e3f3"
        },
        success: {
          // Green
          base: "#1a965a",
          lighten1: "#319f62"
        },
        warning: {
          // Yellow
          base: "#d9ab36",
          lighten5: "#f7eed7"
        },
        background: {
          // Grey
          base: "#8f9cac",
          lighten1: "#bcc5d1",
          lighten2: "#dce2ea",
          lighten3: "#ebeff5",
          lighten5: "#8f9cac"
        },
        overlay: {
          base: "#828194"
        },
        contract: {
          base: "#f3f9f6"
        }
      }
    }
  }
});
