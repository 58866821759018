import { isDateString } from "@/lib/date";

const checkbox = {
  type: "checked",
  error: "Отметьте данный чекбокс",
  validator: (v: boolean): boolean => v
};

const date = {
  type: "date",
  error: "Значение поля не соответствует формату даты",
  validator: (v: string): boolean => {
    return isDateString(v) && /^\d{2}\.\d{4}$/.test(v);
  }
};

const digit = {
  type: "digit",
  error: "Значение поля должно содержать только цифры",
  validator: (v: string): boolean => /^\d+$/.test(v)
};

const digitFloat = {
  type: "digitFloat",
  error: "Значение поля должно содержать цифры",
  validator: (v: string): boolean => /^\d+(?:\.\d{1,6})?$/.test(v)
};

const email = {
  type: "email",
  error: "Значение поля не соответствует формату email"
};

const phone = {
  type: "phone",
  error: "Значение поля не соответствует формату телефонного номера",
  validator: (v: string): boolean => {
    const endRegExp = "([- ]?\\d{2}){2}$";
    const phonePatterns = [
      new RegExp("^(\\d{4}|\\(\\d{4}\\))[- ]?\\d{2}" + endRegExp),
      new RegExp("^(\\d{3}|\\(\\d{3}\\))[- ]?\\d{3}" + endRegExp)
    ];
    return phonePatterns.some(pattern => pattern.test(v));
  }
};

const required = {
  type: "required",
  error: "Заполните данное поле"
};

const passwordCompare = {
  type: "passwordCompare",
  error: "Значение паролей не совпадают",
  validator: (v: string, vm: { [x: string]: string }): boolean => {
    return v === vm.newPwd;
  }
};

export {
  checkbox,
  date,
  digit,
  digitFloat,
  email,
  phone,
  required,
  passwordCompare
};
