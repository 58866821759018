import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
class AuthModule extends VuexModule {
  loggedIn = false;

  @Mutation
  setLoggedIn(loggedIn: boolean) {
    this.loggedIn = loggedIn;
  }
}

export default AuthModule;
