import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
class PreloaderModule extends VuexModule {
  show = false;

  @Mutation
  setShow(show: boolean) {
    this.show = show;
  }
}

export default PreloaderModule;
