














































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapMutations } from "vuex";
import XButton from "../SimpleButton.vue";

@Component({
  components: { XButton },
  methods: {
    ...mapMutations("error", { setShow: "setShow", setMessage: "setMessage" })
  }
})
class Alert extends Vue {
  @Prop({ required: true }) readonly type!: string;
  @Prop({ required: true }) readonly title!: string;
  @Prop({ required: true }) readonly message!: string;

  @Prop({ default: false }) readonly show!: boolean;
}

export default Alert;
