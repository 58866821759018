function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({staticClass:"data-table box box_shadow_initial rounded-lg secondary--text",attrs:{"headers":_vm.preparedHeaders,"items":_vm.preparedItems,"item-class":function () { return _vm.preparedItemsCssClass; },"no-data-text":"Данные отсутствуют","no-results-text":"Совпадения с записями таблицы отсутствуют","footer-props":{
    itemsPerPageOptions: [10, 25, 50, 100, -1],
    itemsPerPageAllText: 'Все',
    itemsPerPageText: 'Кол-во записей на странице',
    pageText: _vm.currentItemsRangeAsText,
  },"hide-default-footer":_vm.items.length < 10,"page":_vm.paginationProps.page,"items-per-page":_vm.paginationProps.itemsPerPage},on:{"update:page":function($event){return _vm.$set(_vm.paginationProps, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.paginationProps, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.paginationProps, "itemsPerPage", $event)},"page-count":function($event){_vm.paginationProps.pageCount = $event}},scopedSlots:_vm._u([(_vm.showTop)?{key:"top",fn:function(){return [_c('div',{staticClass:"data-table__head pa-4"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.preparedCaption),expression:"preparedCaption"}],staticClass:"data-table__caption"},[_vm._v(" "+_vm._s(_vm.preparedCaption)+" ")]),_vm._t("afterCaption")],2)]},proxy:true}:null,(_vm.useHeaderCheckbox)?{key:"header.data-table-select",fn:function(){return [_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"value":_vm.checkbox,"hide-details":""},on:{"click":function($event){return _vm.$emit('checkbox')}}})]},proxy:true}:null,(_vm.useItemSlot)?{key:"item",fn:function(ref){
  var rest = objectWithoutProperties( ref, [] );
  var allProps = rest;
return [_vm._t("item",null,{"item":allProps})]}}:null],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }