import { required, email } from "./field-rules";
import { password } from "./field-icons";

export default [
  {
    name: "fio",
    attrs: {
      type: "text",
      label: "Контактное лицо",
      required: false
    },
    // rules: [required]
  },
  {
    name: "email",
    attrs: {
      type: "text",
      label: "Email",
      required: true
    },
    rules: [required, email]
  },
  {
    name: "accountingPoint",
    attrs: {
      type: "select",
      label: "Точка учета",
      required: false
    },
  },
  {
    name: "message",
    attrs: {
      type: "textarea",
      label: "Текст обращения",
      required: true
    },
    rules: [required]
  },
  {
    name: "file",
    attrs: {
      type: "file",
      label: "Прикрепить файл",
      required: false
    }
  },

];
