
















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
class InvisibleWall extends Vue {
  @Prop({ default: false }) readonly show!: boolean;
}

export default InvisibleWall;
