import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  AccountingPoint,
  AccountingPointsByObject,
  AccountingPointsPUState
} from "@/models/accounting-point";

@Module({ namespaced: true })
class AccountingPointsModule extends VuexModule {
  list: AccountingPoint[] | null = null;
  historyList: AccountingPoint[] | null = null;
  useHistoryList: boolean = false
  useMeteringPointSorting: boolean = false
  accountingPointsPU: AccountingPointsPUState | null = null;

  public get allPoints() {
    return this.list
  }

  public get sortedPointsByObject(): AccountingPointsByObject[] | null {
    const simpleObj: { [x: string]: any } = {};

    const list = this.useHistoryList ? this.historyList : this.list
    const keys = this.useMeteringPointSorting ? ['номерту', 'названиету'] : ['объект', 'названиеобъекта']

    if (list) {
      const points = list.reduce((acc, point) => {
        const objectId = point[keys[0]];
        const objectName = point[keys[1]];

        if (objectId in acc) {
          acc[objectId].pointList.push(point);
        } else {
          acc[objectId] = {
            objectId,
            objectName,
            pointList: [point]
          };
        }

        return acc;
      }, simpleObj);

      return Object.values(points).sort(
        (point1, point2) => point1.objectId - point2.objectId
      );
    }

    return null;
  }

  @Mutation
  setUseHistoryList(use: boolean) {
    this.useHistoryList = use
  }

  @Mutation
  setUseMeteringPointSorting(use: boolean) {
    this.useMeteringPointSorting = use
  }

  @Mutation
  setPoints(accountingPoints: AccountingPoint[] | null) {
    this.list = accountingPoints;
  }

  @Mutation
  setHistoryPoints(accountingPoints: AccountingPoint[] | null) {
    this.historyList = accountingPoints;
  }

  @Mutation
  setPointsPU(accountingPoints: AccountingPointsPUState) {
    this.accountingPointsPU = accountingPoints;
  }
}

export default AccountingPointsModule;
