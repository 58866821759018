import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({ namespaced: true })
class SidebarModule extends VuexModule {
  isMini = true

  @Mutation
  setIsMini(isMini: boolean) {
    this.isMini = isMini
  }
}

export default SidebarModule
