




import { Component, Vue } from "vue-property-decorator";

@Component
class ContractModule extends Vue {}

export default ContractModule;
