import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
class DialogModule extends VuexModule {
  isOpen = false;

  @Mutation
  setIsOpen(isOpen: boolean) {
    this.isOpen = isOpen;
  }
}

export default DialogModule;
