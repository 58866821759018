function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('x-dropdown',_vm._b({attrs:{"items":_vm.itemProps},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-icon',{staticClass:"dropdown__icon"},[_vm._v("mdi-account-circle")]),_c('v-icon',{staticClass:"dropdown__icon"},[_vm._v("mdi-chevron-down")])]},proxy:true},{key:"listItem",fn:function(ref$1){
var ref$1_item = ref$1.item;
var isDivider = ref$1_item.isDivider;
var rest = objectWithoutProperties( ref$1_item, ["isDivider"] );
var ref = rest;
var i = ref$1.i;
return [(isDivider)?_c('v-divider',{key:i}):_c('v-list-item',{key:i,staticClass:"dropdown__item",attrs:{"link":""},on:{"click":ref.handler}},[_c('v-icon',{staticClass:"dropdown__icon"},[_vm._v(_vm._s(ref.icon))]),_c('span',{staticClass:"secondary--text"},[_vm._v(_vm._s(ref.title))])],1)]}}])},'x-dropdown',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }