import { required } from "./field-rules";

export default [
  {
    name: "indication",
    attrs: {
      type: "text",
      label: "",
      required: true
    },
    rules: [ required ]
  }
];
