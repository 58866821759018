

































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
class Card extends Vue {
  @Prop({ default: "" }) readonly headerModClass!: string;
  @Prop({ default: "" }) readonly title!: string;

  @Prop({ default: false }) readonly useHeaderShadow!: boolean;
  @Prop({ default: true }) readonly useMainPaddingBottom!: boolean;

  get showHeader(): boolean {
    const { beforeTitle, afterTitle } = this.$slots;

    return !!(this.title || beforeTitle || afterTitle);
  }
}

export default Card;
