
import { AxiosResponse } from "axios";
import { Component, Vue } from "vue-property-decorator";
import { createLogger } from "vuex";

@Component
class DocGetter extends Vue {
  $refs!: {
    anchor: HTMLAnchorElement;
  };

  public getDocName(contentDisposition: string): string {
    let filename = '';

    if (contentDisposition && contentDisposition.indexOf('filename') !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(contentDisposition);

      if (matches && matches[1])
        filename = matches[1].replace(/['"]/g, '');
    }

    return filename;
  }

  public downloadDocument(response: AxiosResponse): void {
    const contentDisposition: string = response.headers["content-disposition"];
    const { data } = response;

    const objURL = window.URL.createObjectURL(data);

    this.$refs.anchor.href = objURL;
    this.$refs.anchor.download = this.getDocName(contentDisposition);
    this.$refs.anchor.click();

    setTimeout(() => {
      window.URL.revokeObjectURL(data);
    }, 100);
  }
}

export default DocGetter;
