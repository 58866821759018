import {digitFloat, required} from "./field-rules";

export enum MonthsEn {
  january = 1,
  february,
  march,
  april,
  may,
  june,
  july,
  august,
  september,
  october,
  november,
  december
}

enum MonthsRu {
  Январь = 1,
  Февраль,
  Март,
  Апрель,
  Май,
  Июнь,
  Июль,
  Август,
  Сентябрь,
  Октябрь,
  Ноябрь,
  Декабрь
}

const totalMonths = 12;

/*
 * Экспортировать массив из 12 элементов, кол-во элементов соответствует кол-ву
 * месяцев в году. Элемент - объект, содержащий свойства поля ввода. Значения
 * свойств name и prefix являются уникальными для каждого элемента, указывая на
 * принадлежность к определенному месяцу.
 */
export default Array(totalMonths)
  .fill(0)
  .map((_, i) => {
    const monthIndex = i + 1;
    let prefix = `${monthIndex} ${MonthsRu[monthIndex]}`;

    if (monthIndex < 10) {
      prefix = `0${prefix}`;
    }

    return {
      name: MonthsEn[monthIndex],
      attrs: {
        type: "text",
        label: "",
        required: true,
        default: "0",
        prefix,
        monthIndex
      },
      rules: [required, { ...digitFloat, error: "Недопустимый формат" }]
    };
  });
