



















import { Component, Mixins, Prop } from "vue-property-decorator";
import XDialog from "../SimpleDialog.vue";
import XButton from "../SimpleButton.vue";

@Component({
  components: { XDialog, XButton }
})
class ConfirmActionDialog extends Mixins(XDialog) {
  @Prop({ required: true })
  showDialog!: boolean;

  @Prop({ required: true })
  currentConsumption!: string;

  messagesByConsumptionType = {
    zero: "Нулевой расход. Новое показание равно предыдущему. Расход составит",
    negative:
      "Текущее показание меньше предыдущего. Произошла перекрутка счетчика? Текущий расход"
  };

  /**
   * Вычисляемое свойство, которое вычисляет текст сообщения.
   *
   * @returns message текст сообщения, вычисленный на основании значения
   * текущего расхода.
   */
  get currentMessage(): string {
    const { currentConsumption, messagesByConsumptionType } = this;
    const consumptionType =
      parseFloat(currentConsumption) === 0 ? "zero" : "negative";

    return `${messagesByConsumptionType[consumptionType]} ${currentConsumption}`;
  }
}
export default ConfirmActionDialog;
