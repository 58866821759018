import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  HourlyPoint,
  AskueObjects,
  AskuePointsByObject
} from "@/models/hourly-consumption";

@Module({ namespaced: true })
class HourlyConsumptionModule extends VuexModule {
  point: AskueObjects | null = null;
  askueObjects: AskueObjects[] | null = null;
  hourlyPlan: HourlyPoint[] | null = null;
  hourlyFact: HourlyPoint[] | null = null;

  public get sortedAskueByObject(): AskuePointsByObject[] | null {
    const simpleObj: { [x: string]: any } = {};

    if (this.askueObjects) {
      const points = this.askueObjects.reduce((acc, point) => {
        const objectId = point["объект"];
        const objectName = point["названиеобъекта"];
        const objectLevel = 1;

        if (objectId in acc) {
          acc[objectId].pointList.push(point);
        } else {
          acc[objectId] = {
            objectId,
            objectName,
            objectLevel,
            pointList: [point]
          };
        }

        return acc;
      }, simpleObj);

      return Object.values(points).sort(
        (point1, point2) => point1.objectId - point2.objectId
      );
    }

    return null;
  }

  public get pointId(): number | null {
    return this.point && this.point["объект"];
  }

  public get pointLs(): number | null {
    return this.point && this.point["лицевой"];
  }

  public get name(): string | null {
    return this.point && this.point["названиету"];
  }


  public get askueObjectsData(): AskueObjects[] | null {
    return this.askueObjects;
  }

  public get hourlyPlanData(): HourlyPoint[] | null {
    return this.hourlyPlan;
  }

  public get hourlyFactData(): HourlyPoint[] | null {
    return this.hourlyFact;
  }

  @Mutation
  setPoint(point: AskueObjects | null) {
    this.point = point;
  }

  @Mutation
  setAskueObjects(objects: AskueObjects[] | null) {
    this.askueObjects = objects
  }

  @Mutation
  setPointHourlyPlan(hourlyPlan: HourlyPoint[] | null) {
    this.hourlyPlan = hourlyPlan
  }

  @Mutation
  setPointHourlyFact(hourlyFact: HourlyPoint[] | null) {
    this.hourlyFact = hourlyFact
  }
}
export default HourlyConsumptionModule;
