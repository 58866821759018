import { date } from "./field-rules";

export default [
  {
    name: "date",
    attrs: {
      type: "text",
      label: "Дата",
      required: false
    },
    rules: [date]
  }
];
