


































import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
class Dropdown extends Vue {
  @Prop({ default: () => [] }) readonly items!: [];
  @Prop({ default: "" }) readonly modClassValue!: string;

  public get classes(): { dropdown: string; menu: string } {
    const classes = {
      dropdown: "dropdown",
      menu: "dropdown__menu box box_shadow_initial",
    };

    if (this.modClassValue) {
      const modifier = `with_${this.modClassValue}`;

      classes.dropdown += ` dropdown_${modifier}`;
      classes.menu += ` dropdown__menu_${modifier}`;
    }

    return classes;
  }

  public get nudgeBottom(): number | string {
    return this.$attrs["nudge-bottom"] || this.$attrs["nudgeBottom"] || 24;
  }

  @Emit("click")
  public propagateCustomEvt(evtName: string) {
    this.$emit(evtName, evtName);
  }

  protected isObject(item: object | string) {
    return typeof item === "object";
  }
}

export default Dropdown;
