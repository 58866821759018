




























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component export default class Progress extends Vue {
  @Prop() public percentage!: number;
  @Prop({ default: '#42b983' })
  public color!: string;
  @Prop({ default: '#f66' })
  public errorColor!: string;
  @Prop({ default: 'pending' })
  public state!: string;
  public radius: number = 16;
  private circumference = this.radius * 2 * Math.PI;
  private strokeDashoffset = this.circumference;
  @Watch('percentage')
  onPercentageChanged(val: number, oldVal: number) {
    const offset = this.circumference - val / 100 * this.circumference;
    this.strokeDashoffset = offset;
  }
  mounted() {
    this.onPercentageChanged(this.percentage, 0);
  }
}
