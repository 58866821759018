import signInFormFields from "./sign-in-form";
import signUpFormFields from "./sign-up-form";
import countersNewValueFormFields from "./counters-new-value";
import passwordRecoveryFormFields from "./password-recovery-form";
import contractAddingFormFields from "./contract-adding-form";
import emailReplacementFormFields from "./email-replacement-form";
import passwordReplacementFormFields from "./password-replacement-form";
import documentGetterFormFields from "./document-getter-form";
import datePickerFormFields from "./date-picker-form";
import hourlyGetterFormFields from "./hourly-getter-form";
import datePickerAsSingleField from "./date-picker-as-single-field";
import accountingPointPlanFormFields from "./accounting-point-plan-form";
import confirmActionFormFields from "./confirm-action-form";
import appealFormFields from "./appeal-form";
import paymentFormFields from "../form-fields/payment-form";
import { prepareFieldsForComponent } from "@/assets/scripts/form-utils";

const forms = [
  signInFormFields,
  signUpFormFields,
  countersNewValueFormFields,
  passwordRecoveryFormFields,
  contractAddingFormFields,
  emailReplacementFormFields,
  passwordReplacementFormFields,
  documentGetterFormFields,
  datePickerFormFields,
  datePickerAsSingleField,
  accountingPointPlanFormFields,
  confirmActionFormFields,
  appealFormFields,
  hourlyGetterFormFields,
  paymentFormFields
];

// Поля ввода, подготовленные к использованию в рамках соответствующих компонентов.
const [
  signInForm,
  signUpForm,
  countersNewValueForm,
  passwordRecoveryForm,
  contractAddingForm,
  emailReplacementForm,
  passwordReplacementForm,
  documentGetterForm,
  datePickerForm,
  datePickerFormAsSingleField,
  accountingPointPlanForm,
  confirmActionForm,
  appealForm,
  hourlyGetterForm,
  paymentForm
] = forms.map(formFields => prepareFieldsForComponent(formFields));

export {
  signInForm,
  signUpForm,
  countersNewValueForm,
  passwordRecoveryForm,
  contractAddingForm,
  emailReplacementForm,
  passwordReplacementForm,
  documentGetterForm,
  datePickerForm,
  datePickerFormAsSingleField,
  accountingPointPlanForm,
  confirmActionForm,
  appealForm,
  hourlyGetterForm,
  paymentForm
};
