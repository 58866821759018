import { date, required } from "./field-rules";

export default [
  {
    name: "date",
    attrs: {
      type: "text",
      label: "Месяц",
      required: false
    },
    rules: [date]
  },
  {
    name: "format",
    attrs: {
      type: "text",
      label: "Формат",
      required: true
    },
    rules: [required]
  },
  {
    name: "type",
    attrs: {
      type: "text",
      label: "Тип",
      required: true
    },
    rules: [required]
  }
];
