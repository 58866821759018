











import { Component, Prop, Vue } from "vue-property-decorator";

@Component
class List extends Vue {
  @Prop({ required: true }) readonly items!: [];
  @Prop({ default: "" }) readonly keySize!: string;

  get keySizeClass(): string {
    return this.keySize && `list__key_size_${this.keySize}`;
  }
}

export default List;
