var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabs"},[_c('div',{class:[
      'tabs__bar',
      'font-weight-regular',
      'secondary--text',
      'text--lighten-1',
      'text-h6',
      'mb-6',
      'py-4' ]},_vm._l((_vm.titles),function(title,i){return _c('span',{key:title,staticClass:"tabs__tab pb-4 px-4",class:{ tabs__tab_active: _vm.isActiveTab(i) },on:{"click":function($event){_vm.tab = i}}},[_vm._v(" "+_vm._s(title)+" ")])}),0),_vm._l((_vm.components),function(component,i){return [_c(component,{directives:[{name:"show",rawName:"v-show",value:(_vm.isActiveTab(i)),expression:"isActiveTab(i)"}],key:i,tag:"component"})]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }