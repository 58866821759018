import { date } from "./field-rules";

export default [
  {
    name: "startDate",
    attrs: {
      type: "text",
      label: "Начало",
      required: false
    },
    rules: [date]
  },
  {
    name: "endDate",
    attrs: {
      type: "text",
      label: "Окончание",
      required: false
    },
    rules: [date]
  }
];
